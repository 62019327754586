import React, { Suspense, lazy, memo } from "react";
import { useActivityMonitor } from "../hooks/useActivityMonitor.ts";
import LogoutCountdownDialog from "./common/Notifications/dialogs/LogoutCountdownDialog.js";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./Layout";
import Loading from "./common/Loading";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/zh-tw";

dayjs.locale("zh-tw");

// 懶加載組件
const DashboardComponent = lazy(() =>
  import("./userComponent/dashboard-component")
);
const RegisterComponent = lazy(() =>
  import("./userComponent/register-component")
);
const LoginComponent = lazy(() => import("./userComponent/login-component"));
const ResetPasswordComponent = lazy(() =>
  import("./userComponent/resetPassword-component")
);
const ForgotPasswordComponent = lazy(() =>
  import("./userComponent/forgotPassword-component")
);
const ExpenseClaims = lazy(() => import("./Expense/main/ExpenseClaim"));
const MonthlyOpsReport = lazy(() =>
  import("./monthlyOpsReport/MonthlyOpsReport")
);

const ReportEditor = lazy(() =>
  import("./newMonthlyOpsReport/ReportEditor/index.tsx")
);

const NewMonthlyOpsReport = lazy(() =>
  import("./newMonthlyOpsReport/index.tsx")
);

const ReportEditorWrapper = lazy(() =>
  import("./newMonthlyOpsReport/ReportEditorWrapper.tsx")
);

// 受保護路由 HOC
const ProtectedRoute = memo(({ children, currentUser, redirectTo = "/" }) => {
  return currentUser ? (
    children
  ) : (
    <Navigate to={redirectTo} replace state={{ from: redirectTo }} />
  );
});

const AppContent = memo(
  ({ currentUser, handleSetCurrentUser, hasHRAccess }) => {
    const { t } = useTranslation("notifications", "common");
    const { showLogoutDialog, countdown, setCountdown, handleLogout } =
      useActivityMonitor();

    return (
      <>
        <Suspense fallback={<Loading size="default" />}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Routes>
              <Route
                path="/"
                element={
                  <Layout
                    currentUser={currentUser}
                    setCurrentUser={handleSetCurrentUser}
                    hasHRAccess={hasHRAccess}
                  />
                }
              >
                {/* 公開路由 */}
                <Route
                  index
                  element={
                    currentUser ? (
                      <Navigate to="/dashboard" replace />
                    ) : (
                      <LoginComponent setCurrentUser={handleSetCurrentUser} />
                    )
                  }
                />
                <Route
                  path="login"
                  element={
                    currentUser ? (
                      <Navigate to="/dashboard" replace />
                    ) : (
                      <LoginComponent setCurrentUser={handleSetCurrentUser} />
                    )
                  }
                />
                <Route path="register" element={<RegisterComponent />} />
                <Route
                  path="forgot-password"
                  element={<ForgotPasswordComponent />}
                />
                <Route
                  path="reset-password"
                  element={<ResetPasswordComponent />}
                />

                {/* 受保護路由 */}
                <Route
                  path="dashboard"
                  element={
                    <ProtectedRoute currentUser={currentUser} redirectTo="/">
                      <DashboardComponent currentUser={currentUser} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="monthlyOpsReport"
                  element={
                    <ProtectedRoute currentUser={currentUser} redirectTo="/">
                      <MonthlyOpsReport currentUser={currentUser} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="expense_claims"
                  element={
                    <ProtectedRoute currentUser={currentUser} redirectTo="/">
                      <ExpenseClaims
                        currentUser={currentUser}
                        setCurrentUser={handleSetCurrentUser}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="newMonthlyOpsReport/*"
                  element={
                    <ProtectedRoute currentUser={currentUser} redirectTo="/">
                      <Routes>
                        <Route index element={<Navigate to="list" replace />} />
                        <Route
                          path="list"
                          element={
                            <NewMonthlyOpsReport currentUser={currentUser} />
                          }
                        />
                        <Route
                          path="edit/:controlNumber"
                          element={<ReportEditorWrapper />}
                        />
                        <Route
                          path="new"
                          element={<ReportEditor currentUser={currentUser} />}
                        />
                      </Routes>
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Routes>
          </LocalizationProvider>
        </Suspense>

        <LogoutCountdownDialog
          open={showLogoutDialog}
          countdown={countdown}
          setCountdown={setCountdown}
          onLogout={handleLogout}
          title={t("notifications:logout.title")}
          message={t("notifications:logout.message")}
        />
      </>
    );
  }
);

export default AppContent;
