import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GlobalScrollbars from "./components/styled/GlobalScrollbars";
import i18n from "./components/locales/i18n";
import { setCurrentUser } from "./store/authSlice";
import { setDeviceType } from "./store/deviceSlice";
import { isMobile as checkMobile } from "react-device-detect";
import AppContent from "./components/AppContent";

function App() {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDeviceType(checkMobile));
    const savedLanguage = localStorage.getItem("language") || "zhTW";
    i18n.changeLanguage(savedLanguage);
  }, [dispatch]);

  const handleSetCurrentUser = (user) => {
    dispatch(setCurrentUser(user));
  };

  const hasHRAccess =
    currentUser?.user?.role?.some((role) => ["admin", "hr"].includes(role)) ||
    false;

  return (
    <GlobalScrollbars
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
      }}
      autoHide
      autoHideTimeout={1000}
      autoHideDuration={200}
    >
      <BrowserRouter>
        <AppContent
          currentUser={currentUser}
          handleSetCurrentUser={handleSetCurrentUser}
          hasHRAccess={hasHRAccess}
        />
      </BrowserRouter>
    </GlobalScrollbars>
  );
}

export default App;
